import {createSlice, PayloadAction} from '@reduxjs/toolkit'
export interface AppState {
  listLoading: boolean
  actionsLoading: boolean
  totalCount: number
  entities: any[]
  dashboardData: any[]
  totalSchoolDahboard: any[]
  searchItems: any[] | null
  Report: any[]
  data?: any
  singleuserdata?: any
  error: {
    statusCode: number
    message: string
    clientMessage: string
  } | null
  success: string | null
  // Other app state properties
}

const initialState: AppState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  dashboardData: [],
  totalSchoolDahboard: [],
  singleuserdata: [],
  Report: [],
  searchItems: null,
  data: undefined,
  error: null,
  success: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const totelSchoolSlice = createSlice({
  name: 'totelSchool',
  initialState,
  reducers: {
    catchError: (state: any, action: PayloadAction<any>) => {
      const statusCode = action.payload.error.statusCode
      state.error = {
        statusCode: statusCode,
        message: action.payload.error.message,
        clientMessage:
          statusCode === 401
            ? `You are not Authorized to access dashboard`
            : action.payload.error.clientMessage,
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
      state.success = null
    },
    startCall: (state: any, action: PayloadAction<any>) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
      state.success = null
    },
    // getItemById
    itemFetched: (state: any, action: PayloadAction<any>) => {
      state.actionsLoading = false
      state.data = action.payload.data
      state.entities = action.payload.entities
      state.totalCount = action.payload.totalCount
      state.error = null
      state.success = null
    },
    // findItems
    itemsFetched: (state: any, action: PayloadAction<any>) => {
      const usersList = action.payload
      // console.log('table*******', usersList)
      state.listLoading = false
      state.error = null
      state.entities = usersList
      state.success = null
      // state.totalCount = totalCount
    },

    SearchReportFetched: (state: any, action: PayloadAction<any>) => {
      const searchUsersList = action.payload
      // console.log('table*******', usersList)
      state.listLoading = false
      state.error = null
      state.Report = searchUsersList
      state.success = null
      // state.totalCount = totalCount
    },
    // findSelectItems
    selectItemsFetched: (state: any, action: PayloadAction<any>) => {
      const {searchItems} = action.payload
      state.listLoading = false
      state.error = null
      state.searchItems = searchItems
      state.success = null
    },
    // createItem
    itemCreated: (state: any, action: PayloadAction<any>) => {
      state.actionsLoading = false
      state.error = null
      state.entities.push(action.payload.data)
      state.success = 'dashboardData created Successfully'
    },

    totelschooldashboard: (state: any, action: PayloadAction<any>) => {
      // console.log('action.payload.totelSchoolData', action.payload.totelSchoolData)

      state.actionsLoading = false
      state.error = null
      state.totalSchoolDahboard.push(action.payload.totelSchoolData)
      state.success = 'dashboard created Successfully'
    },

    dashboarditemCreated: (state: any, action: PayloadAction<any>) => {
      // console.log('action.payload.totelSchoolData', action.payload.dashboardData)

      state.actionsLoading = false
      state.error = null
      state.dashboardData.push(action.payload.dashboardData)
      state.success = 'dashboard created Successfully'
    },
    singleUserDataContainer: (state: any, action: PayloadAction<any>) => {
      // console.log('action.payload.totelSchoolData', action.payload)

      state.actionsLoading = false
      state.error = null
      state.singleuserdata.push(action.payload.singleuserdata.userData)
      state.success = 'dashboard created Successfully'
    },
    // cloneItem
    itemCloned: (state: any, action: PayloadAction<any>) => {
      state.actionsLoading = false
      state.error = null
      state.entities.push(action.payload.data)
      state.success = null
    },
    // updateItem
    itemUpdated: (state: any, action: PayloadAction<any>) => {
      state.error = null
      state.actionsLoading = false
      state.success = 'dashboard Updated Successfully'
      state.entities = state.entities?.map((entity: any) => {
        if (entity.id === action.payload.data.id) {
          return action.payload.data
        }
        return entity
      })
    },
    // deleteItem
    itemDeleted: (state: any, action: PayloadAction<any>) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities?.filter((el: any) => el.id !== action.payload.id)
      state.success = null
    },
    // deleteLanguageMasters
    itemsDeleted: (state: any, action: PayloadAction<any>) => {
      state.success = null
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities?.filter((el: any) => !action.payload.ids.includes(el.id))
    },
    // languageMastersUpdateState
    itemsStatusUpdated: (state: any, action: PayloadAction<any>) => {
      state.success = null
      state.actionsLoading = false
      state.error = null
      const {ids, status} = action.payload
      state.entities = state.entities?.map((entity: any) => {
        if (ids.findIndex((id: any) => id === entity.id) > -1) {
          entity.status = status
        }
        return entity
      })
    },
  },
})
