import {useEffect, useState, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {ChartsWidget3, MixedWidget11} from '../../../_metronic/partials/widgets'
import ProgressBar from '../../../_utility/progressBar'
import ProgressCircle from '../../../_utility/progressCircle'
import MyDateRangePickerComponent from '../../../_utility/DatePicker'
import {Link} from 'react-router-dom'
import * as actions from './_redux/actions'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import ProgressBar2 from '../../../_utility/progressBar2'
import {useAuth} from '../../modules/auth'
import SpecialLoader from '../../../_utility/SpecialLoader'

const DashboardPage = () => {
  const {queryAndbodyTableData, handleInputChange} = useAuth()
  const [DaySession, setDaySession] = useState(7)
  const dispatch: Dispatch<any> = useDispatch()
  const {
    actionsLoading,
    errors,
    success,
    data: responseData,
    entities,
  } = useSelector(
    (state: any) => ({
      actionsLoading: state.dashboard.actionsLoading,
      errors: state.dashboard.error,
      success: state.dashboard.success,
      data: state.dashboard.dashboardData,
      entities: state.dashboard.entities,
    }),
    shallowEqual
  )

  // console.log('actionsLoading', actionsLoading)

  const dashboardData = useMemo(() => {
    // console.log('entities', entities)
    return responseData.length > 0 ? responseData[responseData.length - 1] : null
  }, [responseData])

  // console.log('dashboardData', dashboardData)

  // ==========Module Clicks/Impression (progress bar)============ //

  const progressData1 = [...(dashboardData?.moduleClicks || [])]
  // console.log('progressData1', progressData1)

  // Function to categorize URLs

  function categorizeURL(input: any) {
    // Handle null input
    if (input === null) {
      return 'Undefined'
    }

    try {
      // Attempt to process the input as a URL
      let path = new URL(input).pathname
      let moduleName = path
        .split('/')
        .filter((part) => part.length > 0)
        .pop()
      if (!moduleName) {
        return 'Website' // Handle case with no value after '/'
      }
      moduleName = moduleName.replace(/_/g, ' ') // Replace underscores with spaces
      moduleName = moduleName.replace(/\b\w/g, (l) => l.toUpperCase()) // Capitalize first letter of each word
      return moduleName
    } catch (error) {
      // Input is not a valid URL, treat as regular string
      return input
        .split(' ')
        .map((part: any) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join(' ')
    }
  }

  // Add a new key 'category' to each object in moduleClicks
  const moduleClicksWithCategory = progressData1.map((item) => ({
    ...item,
    category: categorizeURL(item.screen_or_page),
  }))

  // console.log('===========', moduleClicksWithCategory)

  // ==========Institute Logins data============ //

  let progressData2 = [...(dashboardData?.instituteLogins || [])] // Create a new array

  if (progressData2.length > 0) {
    progressData2.sort((a: any, b: any) => b.daily_login_count - a.daily_login_count)
  }

  const InstituteLogin = progressData2
  // ---------------------Active hour data ------------------------------//
  const stringWithoutDecimal = dashboardData?.activeHours[0]?.total_active_hours || ''
  const ActiveHours = String(Math.round(Number(stringWithoutDecimal)))
  // ------------------------Average Session----------------------------//
  const formattedData = dashboardData?.avgEventCount || []
  let Average_session: any = []

  const daysOfWeek = formattedData.map((item: any) => {
    // Extract the first three characters of the string and capitalize them
    const firstThreeLetters = item.day_of_week.substring(0, 3).toUpperCase()
    // Concatenate the capitalized letters with the rest of the string
    return firstThreeLetters
  })

  if (formattedData.length > 0) {
    Average_session = [
      {
        name: 'Average Session',
        data: formattedData.map((item: any) => Math.floor(item.average_event_count)),
      },
    ]
  }
  // ======================  Session By City  =======================

  const sessionBYcity = dashboardData?.sessionByCities || []
  const cityName = sessionBYcity.map((item: any) => item.city)

  let cityEventCount: any = []

  if (sessionBYcity.length > 0) {
    cityEventCount = [
      {
        name: 'Session By City',
        data: sessionBYcity.map((item: any) => Math.floor(item.event_count)),
      },
    ]
  }
  // console.log('sessionBYcity', sessionBYcity, cityName, cityEventCount)

  // ====================== Session By Device  ========================
  const SessionByDevice = [...(dashboardData?.sessionWithDevice || [])]
  const NewAndReturningUsers = [...(dashboardData?.newAndReturningUsers || [])]
  // console.log('SessionByDevice', SessionByDevice)

  const progressCircleData1 = {
    text: 'Site Sessions',
    value: SessionByDevice[0]?.category_percentage,
    size: 100,
    circleColor: '#1ABEF3',
    progressColor: '#1684EA',
    totelValue: SessionByDevice[0]?.total_session_count,
  }

  const progressCircleData = {
    text: 'Unique Visitors',
    value: NewAndReturningUsers[0]?.percentage_new_visitors,
    size: 100,
    circleColor: '#1ABEF3',
    progressColor: '#1684EA',
    totelValue: NewAndReturningUsers[0]?.total_unique_users,
  }
  // ======================== Sessions Chart =======================//

  const sessionChart = [...(dashboardData?.session || [])]

  const selectedData = sessionChart.slice(0, DaySession).reverse()
  const event = selectedData.map((entry) => entry.event_day.value)
  const dailySessionCounts = selectedData.map((entry) => String(entry.daily_session_count))

  const sumBreakPoint = dailySessionCounts.reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue),
    0
  )
  // console.log('Sum of breakPoint:', sumBreakPoint)

  const eventDays = event.map((dateString) => {
    const [year, month, day] = dateString.split('-')
    const monthNames: {[key: string]: string} = {
      '01': 'JAN',
      '02': 'FEB',
      '03': 'MAR',
      '04': 'APR',
      '05': 'MAY',
      '06': 'JUN',
      '07': 'JUL',
      '08': 'AUG',
      '09': 'SEP',
      '10': 'OCT',
      '11': 'NOV',
      '12': 'DEC',
    }
    const monthName = monthNames[month]
    return `${monthName} ${day}`
  })

  console.log('eventDays', eventDays)

  const generateChartData = (name: string, brakPoint: string[], categories: string[]) => ({
    name,
    brakPoint,
    categories,
  })

  let chartdata2 = {}
  let chartdata3 = {}
  let chartdata4 = {}

  if (sessionChart.length > 0 && DaySession) {
    chartdata2 = generateChartData('session', dailySessionCounts, eventDays)
    chartdata3 = generateChartData('session', dailySessionCounts, eventDays)
    chartdata4 = generateChartData('session', dailySessionCounts, eventDays)
  }

  useEffect(() => {
    let {pageNumber, pageSize, ...newqueryAndbodyTableData} = queryAndbodyTableData
    dispatch(actions.dashbordCreateItem(newqueryAndbodyTableData))
  }, [dispatch, queryAndbodyTableData, DaySession])

  return (
    <>
      {actionsLoading ? (
        <SpecialLoader />
      ) : (
        <>
          <div></div>
          {/* <Calendar /> */}
          <div className='col-lg-3 col-md-4 dash-calender tr-shadow default-color'>
            {/* <p className='fs-5'>Select Date</p> */}
            <MyDateRangePickerComponent handleInputChange={handleInputChange} />
          </div>
          <div className='row mt-4 mx-1 dash-calender tr-shadow default-color'>
            <div className='col-lg-3 col-md-3 col-sm-12 border-right'>
              <Link to='/download'>
                <p className='dash-text'>Download</p>

                <div className='d-flex justify-content-end mt-2'>
                  {dashboardData?.downloads.map((i: any, index: number) => (
                    <div className='me-4' key={index}>
                      <p className='mb-0 ' style={{color: '#707070'}}>
                        {i.platform}
                      </p>
                      <h1 style={{color: '#707070'}}>{i.download_count}</h1>
                    </div>
                  ))}
                </div>
              </Link>
            </div>
            <div className='col-lg-3 col-md-3 col-sm-12 border-right'>
              <Link to='/institute-wise-report'>
                <p className='dash-text'>Total Logins</p>{' '}
                <p className='dash-text-num' style={{color: '#707070'}}>
                  {dashboardData ? Math.floor(dashboardData?.totalLogins[0]?.login_count) : 0}
                </p>
              </Link>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-12  border-right'>
              <p className='dash-text'>Active Hours</p>
              <p className='dash-text-num'>{`${ActiveHours} `}</p>
            </div>
            <div className='col-lg-3 col-md-3 col-sm-12'>
              <p className='dash-text'>Total Bug</p>
              <p
                className='dash-text-num'
                style={{color: 'red'}}
              >{`${dashboardData?.totalBugs[0].counts} `}</p>
            </div>
          </div>

          <div className='row mt-4 default-color'>
            <div className='col-lg-6 mb-2'>
              <div className='card'>
                <div className='d-flex justify-content-between p-5'>
                  <div>
                    <p className='mb-0 fs-5'>
                      {' '}
                      <b> Total Sessions Count</b>
                    </p>
                    <h1 className='mb-0'>{sumBreakPoint}</h1>
                    {/* <p style={{color: '#51D251', fontSize: '20px'}}>+50% in past 7 days</p> */}
                  </div>
                </div>

                <div className='d-flex justify-content-start mb-5'>
                  <button
                    className={`mx-3 ${DaySession === 7 ? 'button-select' : 'button-unselect'}`}
                    role='button'
                    onClick={() => {
                      setDaySession(7)
                    }}
                  >
                    7 Day
                  </button>
                  <button
                    className={`mx-3 ${DaySession === 14 ? 'button-select' : 'button-unselect'}`}
                    role='button'
                    onClick={() => {
                      setDaySession(14)
                    }}
                  >
                    14 Day
                  </button>
                  <button
                    className={` mx-3 ${DaySession === 29 ? 'button-select' : 'button-unselect'}`}
                    role='button'
                    onClick={() => setDaySession(29)}
                  >
                    28 Day
                  </button>
                </div>

                {sessionChart.length > 0 && DaySession && selectedData.length > 0 ? (
                  <>
                    {DaySession === 7 && <ChartsWidget3 className='' chartdata={chartdata2} />}
                    {DaySession === 14 && <ChartsWidget3 className='' chartdata={chartdata3} />}
                    {DaySession === 29 && <ChartsWidget3 className='' chartdata={chartdata4} />}
                  </>
                ) : (
                  <p className='text-center'>Data is empty</p>
                )}
              </div>
              <div className='progress-circle-box-grid mt-8'>
                <div className='card'>
                  <p className='px-5 mb-0 mt-4 fs-6'>
                    <b> New vs Returning Visitors</b>
                  </p>
                  <hr />
                  <div className='row px-3 pb-3 '>
                    <div className='col text-center'>
                      <ProgressCircle progressCircleData={progressCircleData} />
                    </div>
                    <div className='col'>
                      <div>
                        <li style={{color: `${progressCircleData.progressColor}`}}>
                          <span
                            style={{
                              color: '#707070',
                            }}
                            className='small-text'
                          >
                            New
                          </span>
                        </li>
                        <div className='d-flex'>
                          <p className='me-3 fw-bold small-text'>
                            {NewAndReturningUsers[0]?.percentage_new_visitors}%
                          </p>
                          <li className='small-text'>{NewAndReturningUsers[0]?.new_visitors}</li>
                        </div>
                      </div>
                      <div>
                        <li style={{color: `${progressCircleData.circleColor}`}}>
                          <span
                            style={{
                              color: '#707070',
                            }}
                            className='small-text'
                          >
                            Returning
                          </span>
                        </li>
                        <div className='d-flex'>
                          <p className='me-3 fw-bold small-text'>
                            {NewAndReturningUsers[0]?.percentage_returning_visitors}%
                          </p>
                          <li className='small-text'>
                            {NewAndReturningUsers[0]?.returning_visitors}
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card '>
                  <p className='px-5 mb-0 mt-4 fs-6'>
                    {' '}
                    <b>Session By Device</b>{' '}
                  </p>
                  <hr />
                  <div className='row px-3 pb-3'>
                    <div className='col text-center'>
                      <ProgressCircle progressCircleData={progressCircleData1} />
                    </div>
                    <div className='col'>
                      <div>
                        <li style={{color: `${progressCircleData1.progressColor}`}}>
                          <span
                            style={{
                              color: '#707070',
                            }}
                            className='small-text'
                          >
                            Desktop
                          </span>
                        </li>
                        <div className='d-flex'>
                          <p className='me-3 fw-bold small-text'>
                            {SessionByDevice[0]?.category_percentage}%
                          </p>
                          <li className='small-text'>{SessionByDevice[0]?.session_count}</li>
                        </div>
                      </div>
                      <div>
                        <li style={{color: `${progressCircleData1.circleColor}`}}>
                          <span
                            style={{
                              color: '#707070',
                            }}
                            className='small-text'
                          >
                            Mobile
                          </span>
                        </li>
                        <div className='d-flex'>
                          <p className='me-3 fw-bold small-text'>
                            {SessionByDevice[1]?.category_percentage}%
                          </p>
                          <li className='small-text'>{SessionByDevice[1]?.session_count}</li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 '>
              <div className='card p-5' style={{height: '300px', overflow: 'auto'}}>
                <p className='fs-4 mb-4'>
                  <b>Module Clicks/Impression</b>
                </p>
                <ProgressBar progressData={moduleClicksWithCategory} />
              </div>
              <div className='card mt-8 '>
                <p className='px-5 pt-4 fs-4'>
                  <b> Users</b>
                </p>
                <hr className='mt-0 p-5' />
                <div className='d-flex justify-content-around pb-4'>
                  <div>
                    <p className='large-text mb-2'>{dashboardData?.users[0]?.staff}</p>
                    <p className='text-center'>Employee</p>
                  </div>
                  <div>
                    <p className='large-text mb-2'>{dashboardData?.users[0]?.students}</p>
                    <p className='text-center'>Student</p>
                  </div>
                </div>
              </div>
              <div className='card mt-9'>
                {/* <Link to='/subcription'> */}
                <p className='px-5 pt-4 fs-4 default-color'>
                  <b>Subscriptions-Enrolled</b>
                </p>
                {/* </Link> */}
                <hr className='mt-0' />
                <div className='d-flex justify-content-around m-4 '>
                  <div>
                    <p className='large-text orange-color mb-2'>0</p>
                    <p className='text-center orange-color'>Free</p>
                  </div>
                  <div>
                    <p className='large-text green-color mb-2'>0</p>
                    <p className='text-center green-color'>Basic</p>
                  </div>
                  <div>
                    <p className='large-text red-color mb-2'>0</p>
                    <p className='text-center red-color'>Pro</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-4 default-color'>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card p-5 mt-4' style={{height: '370px', overflow: 'auto'}}>
                <p className='fs-4 mb-4'>
                  <b>Institute Logins</b>
                </p>
                <ProgressBar2 progressData={InstituteLogin} />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              {formattedData.length > 0 ? (
                <MixedWidget11
                  className='card-xl-stretch mb-xl-8  mt-4'
                  chartColor='info'
                  chartHeight='290px'
                  info={Average_session}
                  title='Average Session by day'
                  categoriesData={daysOfWeek}
                />
              ) : (
                ''
              )}
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              {sessionBYcity.length > 0 ? (
                <MixedWidget11
                  className='card-xl-stretch mb-xl-8  mt-4'
                  chartColor='info'
                  chartHeight='240px'
                  info={cityEventCount}
                  title='Session By City'
                  categoriesData={cityName}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
