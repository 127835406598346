import {combineReducers} from 'redux'
import {dashboardSlice} from '../app/pages/dashboard/_redux/slice'
import {totelSchoolSlice} from '../app/modules/institiuteWiseReport/_redux/slice'
import {activeUserSlice} from '../app/modules/activeUsers/_redux/slice'
import {downloadSlice} from '../app/modules/download/_redux/slice'
const rootReducer = combineReducers({
  dashboard: dashboardSlice.reducer,
  totelSchool: totelSchoolSlice.reducer,
  activeUser: activeUserSlice.reducer,
  download: downloadSlice.reducer,
})
export default rootReducer
