import {Dispatch, AnyAction} from 'redux'
import * as requestFromServer from './crud'
import {dashboardSlice, callTypes} from './slice'
// import { CommonErrorHandle } from '../../../../../_components/commanErrorHandling'

const {actions} = dashboardSlice

export const dashbordCreateItem = (data: any) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(actions.startCall({callType: callTypes.action}))
  return requestFromServer
    .dashboardcreateItem(data)
    .then((response) => {
      let dashboardData = response.data

      dispatch(actions.dashboarditemCreated({dashboardData}))
    })
    .catch((error) => {
      console.log(error)
      // CommonErrorHandle(dispatch,actions,error,callTypes)
    })
}
