import React from 'react'

const SpecialLoader = () => {
  return (
    // <div className='ring'>
    //   Loading
    //   <span className='ring1'></span>
    // </div>
    <img src='/media/loader/loading.gif' className='light-logo ring' alt='Metronic light logo' />
  )
}

export default SpecialLoader
