export const chartdata3 = {
  name: 'Sessions',
  brakPoint: [30, 40, 40, 90, 90, 70, 70],
  categories: ['Nov 11', 'Nov 12', 'Nov 13', 'Nov 14', 'Nov 15', 'Nov 16', 'Nov 17'],
}

export const chartdata2 = {
  name: 'Active User',
  brakPoint: [300, 400, 400, 900, 900, 700, 700],
  // categories: ['Nov 11', 'Nov 12', 'Nov 13', 'Nov 14', 'Nov 15', 'Nov 16', 'Nov 17'],
}

export const progressData = [
  {
    name: 'Performance',
    value: 50,
    Total: 656,
  },
  {
    name: 'Classwork Homework',
    value: 45,
    Total: 656,
  },
  {
    name: 'Syllabus',
    value: 40,
    Total: 656,
  },
  {
    name: 'Online learning',
    value: 65,
    Total: 656,
  },

]

export const progressCommunoicationReportData = [
  {
    name: 'Whatapp',
    value: 50,
    Total: 656,
  },
  {
    name: 'Flash Message',
    value: 45,
    Total: 656,
  },
  {
    name: 'SMS',
    value: 40,
    Total: 656,
  },
  {
    name: 'Direct Message',
    value: 65,
    Total: 656,
  },
  {
    name: 'Email',
    value: 20,
    Total: 656,
  },
]

export const InstituteprogressData = [
  {
    name: 'Ambuja School',
    value: 50,
    Total: 656,
  },
  {
    name: 'Central Academy, CHB',
    value: 45,
    Total: 656,
  },
  {
    name: 'Lorem ipsum',
    value: 20,
    Total: 656,
  },
  {
    name: 'Central Academy, Paota',
    value: 70,
    Total: 656,
  },
]

export const progressCircleData = {
  text: 'Unitque Visitors',
  value: 75,
  size: 100,
  circleColor: '#1ABEF3',
  progressColor: '#1684EA',
}

export const progressCircleData1 = {
  text: 'Site Sessions',
  value: 0,
  size: 100,
  circleColor: '#1ABEF3',
  progressColor: '#1684EA',
}

export const barData = [
  {
    name: 'Average Session',
    data: [286.5, 809.5, 810, 822.25, 798.25, 782, 526.5]
}
]

export const userRoles = {
  superAdmin: "Super Admin",
  BranchAdmin: "Branch Admin",
  BranchAdmins: "BranchAdmin",
  InstituteAdmin: "Institute Admin",
  parents: "Parents",
  parent:"Parent"
};

export const RtlLanguages = ["ar", "he", "ur", "fa", "ps"];

export const redirectUrl = process.env.REACT_APP_ROUVR_URL


