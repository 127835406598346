import {FC, useEffect} from 'react'
import {Routes, Route, Navigate, useLocation, useNavigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const location = useLocation();
  const {currentUser} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let auhtToken = localStorage.getItem("authToken")
    if (!currentUser && location.pathname !== '/validate-token' && !auhtToken) {
      navigate('/redirect')
    }
  }, [currentUser])

  return (
    <Routes>
      <Route element={<App />}>
        {!currentUser || location.pathname === '/validate-token' ? (
          <>
            <Route path='/*' element={<AuthPage />} />
            {/* <Route path='*' element={<Navigate to='/redirect' />} /> */}
          </>
        ) : (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to='/dashboard' />} />
          </>
        )}
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
      </Route>
    </Routes>
  )
}

export {AppRoutes}
