import axios from 'axios'
import {AuthModel, UserModel} from './_models'

// const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${process.env.REACT_APP_SSO_API_URL}login`;
export const VALIDATE_TOKEN = `${process.env.REACT_APP_SSO_API_URL}validate-token`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_SSO_API_URL}user/me`;
export const USER_PERMISSIONS_URL = `${process.env.REACT_APP_AUTHORIZATION_API_URL}user-permissions`;
export const GET_LIBRARY_DATA_URL = `${process.env.REACT_APP_API_URL}library-masters/manage`;
export const SET_LIBRARY_URL = `${process.env.REACT_APP_SSO_API_URL}set-library`;
export const GET_STUDENT_PROFILES_URL = `${process.env.REACT_APP_SSO_API_URL}get-student-profiles`;
export const SET_STUDENT_PROFILE_URL = `${process.env.REACT_APP_SSO_API_URL}set-student-profile`;

// Server should return AuthModel
export async function login(email: string, password: string) {
  const response = await axios.post<AuthModel>(LOGIN_URL, { email, password });

  const redirectUrl = response.data.redirectUrl;
  const referralToken = redirectUrl.substring(
    redirectUrl.indexOf("=") + 1,
    redirectUrl.indexOf("&")
  );

  const tokens = await axios.post<AuthModel>(VALIDATE_TOKEN, { referralToken });
  return tokens;
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken() {
  return axios.get(ME_URL)
}

export function getInstitutes() {
  return axios.get(`${process.env.REACT_APP_SSO_API_URL}get-institutes`);
  // return axios.get(`https://stgssoapi.edprime.cloud/get-institutes`);
}


export function getBranches() {
  return axios.get(`${process.env.REACT_APP_SSO_API_URL}get-branches`);
}

export function setInstituteBranch(data: any) {
  return axios.post(
    `${process.env.REACT_APP_SSO_API_URL}set-institute-branch`,
    data
  );
}
export async function setLibrary(libraryId: string) {
  return await axios.post(SET_LIBRARY_URL, {libraryId})
}
export async function getLibrary(branchName: string, branchId: string) {
  return await axios.post(`${GET_LIBRARY_DATA_URL}`, {
    branchName,
    branchId,
  })
}

export async function validateReferralToken(referralToken:string) {  //vipul
  const tokens = await axios.post(VALIDATE_TOKEN, {referralToken});

  return tokens;
}

//-----------------student selection---------------

export async function getStudentProfiles() {
  return await axios.get(GET_STUDENT_PROFILES_URL);
}


export function getClass() {
  return axios.get(`https://examapi.edprime.co/get-class-names`);
}

export function getStudent(str:string) {
  return axios.get(`${process.env.REACT_APP_SSO_API_URL}search-students?search=${str}`);
}

export function postStudent(data:any) {
  return axios.post(`${process.env.REACT_APP_SSO_API_URL}students/list`,data);
  // return axios.post(`https://stgssoapi.edprime.cloud/students/list`,data);
}


// export async function setStudentProfile(data) {
//   const tokens = await axios.post(SET_STUDENT_PROFILE_URL, {
//     ...data,
//     studentId: data.studentId ? data.studentId.toString() : "",
//   });

//   return tokens;
// }