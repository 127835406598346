import React, {useEffect, useState} from 'react'

interface ProgressCircleProps {
  progressCircleData: {
    value: number
    size: number
    circleColor: string
    progressColor: string
    text: string
    totelValue?: string
  }
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({progressCircleData}) => {
  const {value, size, circleColor, progressColor, text, totelValue} = progressCircleData
  const strokeWidth = 5
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const progress = (value * circumference) / 100
    setOffset(circumference - progress)
  }, [value, circumference])

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill='none'
        stroke={circleColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill='none'
        stroke={progressColor}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        style={{transition: 'stroke-dashoffset 0.3s ease'}}
      />
      <text x='50%' y='50%' textAnchor='middle' dominantBaseline='middle' fontSize='12' fill='#000'>
        <tspan style={{fontSize: '10px'}} dy='-0.6em'>
          {text}
        </tspan>
        <tspan x='50%' dy='1.2em' style={{fontWeight: '600'}}>
          {totelValue}
        </tspan>
      </text>
    </svg>
  )
}

export default ProgressCircle
