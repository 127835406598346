import React from 'react'

interface ProgressBarProps {
  progressData?: any[]
}

const ProgressBar: React.FC<ProgressBarProps> = ({progressData}) => {
  // console.log('progressData-progressData', progressData)

  return (
    <>
      {progressData?.map((el: any, i: number) => {
        // console.log('el.view_count_percentage_diff', el.view_count_percentage_diff)
        return (
          <div className='d-flex align-items-center w-100 flex-column mt-3' key={i}>
            <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
              <span className='fw-bold fs-6 text-gray-400'>{el?.category}</span>
              <span style={{color: `${el.view_count_percentage_diff <= 1 ? 'red' : '#51D251'}`}}>
                {`${el.view_count_percentage_diff < 0 ? '' : '+'}${Math.floor(
                  el.view_count_percentage_diff
                )}`}
                %<span className='fs-2 default-color'>|{el?.view_count}</span>
              </span>
            </div>
            <div className='h-5px mx-3 w-100 bg-light mb-3'>
              <div
                className={`${el.view_count_percentage_diff > 1 ? 'bg-success' : ''} rounded h-5px`}
                role='progressbar'
                style={{
                  width: `${
                    el.view_count_percentage_diff <= 0
                      ? 0.5
                      : el.view_count_percentage_diff > 100
                      ? 100
                      : el.view_count_percentage_diff
                  }%`,
                  backgroundColor: 'red',
                }}
              ></div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ProgressBar
