import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const ActiveUserPsges = lazy(() => import('../modules/activeUsers/pages/ActiveUserPsges'))

  const CommunicationPage = lazy(() => import('../modules/communicationReport/pages/Index'))
  const SubcriptionPages = lazy(() => import('../modules/Subcription/pages/index'))
  const TotalStudentPages = lazy(() => import('../modules/totelstudent/pages/index'))
  const DownloadPages = lazy(() => import('../modules/download/pages/download'))
  const InstituteWiseReportPages = lazy(() => import('../modules/institiuteWiseReport/pages/index'))
  const SearchReportPages = lazy(
    () => import('../modules/institiuteWiseReport/pages/SearchProfile')
  )
  const SearchReportProfilePages = lazy(
    () => import('../modules/institiuteWiseReport/pages/SearchUserReport')
  )
  const BugPages = lazy(() => import('../modules/bug/pages/Bug'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='/*' element={<Navigate to='/dashboard' />} />
        {/* <Route path='/' element={<Navigate to='/dashboard' />} /> */}
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='active-user/*'
          element={
            <SuspensedView>
              <ActiveUserPsges />
            </SuspensedView>
          }
        />

        <Route
          path='communication-report/*'
          element={
            <SuspensedView>
              <CommunicationPage />
            </SuspensedView>
          }
        />
        <Route
          path='/subcription/*'
          element={
            <SuspensedView>
              <SubcriptionPages />
            </SuspensedView>
          }
        />
        <Route
          path='totel-school/*'
          element={
            <SuspensedView>
              <TotalStudentPages />
            </SuspensedView>
          }
        />
        <Route
          path='download/*'
          element={
            <SuspensedView>
              <DownloadPages />
            </SuspensedView>
          }
        />

        <Route
          path='institute-wise-report/*'
          element={
            <SuspensedView>
              <InstituteWiseReportPages />
            </SuspensedView>
          }
        />
        <Route
          path='search/*'
          element={
            <SuspensedView>
              <SearchReportPages />
            </SuspensedView>
          }
        />
        <Route
          path='search/details/:logged_in_email'
          element={
            <SuspensedView>
              <SearchReportProfilePages />
            </SuspensedView>
          }
        />
        <Route
          path='bug/*'
          element={
            <SuspensedView>
              <BugPages />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
