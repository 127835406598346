import {createSlice, PayloadAction} from '@reduxjs/toolkit'
export interface AppState {
  listLoading: boolean
  actionsLoading: boolean
  totalCount: number
  entities: any[]
  downloadItem: any[]
  searchItems: any[] | null
  data?: any
  singleuserdata?: any
  error: {
    statusCode: number
    message: string
    clientMessage: string
  } | null
  success: string | null
  // Other app state properties
}

const initialState: AppState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  downloadItem: [],
  singleuserdata: [],
  searchItems: null,
  data: undefined,
  error: null,
  success: null,
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const downloadSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    catchError: (state: any, action: PayloadAction<any>) => {
      const statusCode = action.payload.error.statusCode
      state.error = {
        statusCode: statusCode,
        message: action.payload.error.message,
        clientMessage:
          statusCode === 401
            ? `You are not Authorized to access dashboard`
            : action.payload.error.clientMessage,
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
      state.success = null
    },
    startCall: (state: any, action: PayloadAction<any>) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
      state.success = null
    },

    dashboarditemCreated: (state: any, action: PayloadAction<any>) => {
      console.log('action.payload.totelSchoolData', action.payload.downloadItem)

      state.actionsLoading = false
      state.error = null
      state.downloadItem.push(action.payload.downloadItem)
      state.success = 'dashboard created Successfully'
    },
  },
})
