import React, {useState, ReactNode} from 'react'
import {DatePicker} from 'antd'
import moment, {Moment} from 'moment'
import {useAuth} from '../app/modules/auth'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
const {RangePicker} = DatePicker

interface MyDateRangePickerProps {
  children?: ReactNode
  handleInputChange?: any
}

const MyDateRangePickerComponent: React.FC<MyDateRangePickerProps> = ({handleInputChange}) => {
  const [selectedDateRange, setSelectedDateRange] = useState<Moment[] | null>(null)
  const {queryAndbodyTableData} = useAuth()

  const StartDate = moment(queryAndbodyTableData.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
  const EndDate = moment(queryAndbodyTableData.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')

  const handleDateChange = (dates: any, dateStrings: any) => {
    // Add a null check for the 'dates' parameter
    if (dates && dates.length === 2) {
      let Propdata = {
        startDate: dates[0]?.format('YYYY-MM-DD'),
        endDate: dates[1]?.format('YYYY-MM-DD'),
      }
      setSelectedDateRange(dates)
      handleInputChange(Propdata)
    } else {
      // Handle the case when 'dates' is null or not an array with length 2
      console.error("Invalid 'dates' parameter:", dates)
    }
  }
  const dateFormat = 'YYYY/MM/DD'
  return (
    <div style={{width: '100%', fontSize: '10px'}}>
      <RangePicker
        defaultValue={[dayjs(StartDate, dateFormat), dayjs(EndDate, dateFormat)]}
        onChange={handleDateChange}
        format='DD-MM-YYYY'
      />
    </div>
  )
}

export default MyDateRangePickerComponent
function SetDate(selectedDateRange: moment.Moment[] | null) {
  throw new Error('Function not implemented.')
}
