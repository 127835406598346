import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

declare const localStorage: Storage;

export default function setupAxios(httpClient: typeof axios, store: any): void {
  let isRefreshing = false;
  let refreshSubscribers: Array<(token: string) => void> = [];

  httpClient.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const { accessToken } = JSON.parse(localStorage.getItem('authToken') || '{}') as { accessToken: string | null };

      if (!config.baseURL) {
        config.baseURL = process.env.REACT_APP_API_URL;
      }

      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'client-id': process.env.REACT_APP_SSO_CLIENT_ID as string,
      };

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  httpClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const {
        config,
        response: { status  }= {} as AxiosResponse,
      } = error;
      const { refreshToken } = JSON.parse(localStorage.getItem('authToken') || '{}') as { refreshToken: string | null };

      const originalRequest = config;

      if (
        status === 401 &&
        originalRequest.url !== `${process.env.REACT_APP_SSO_API_URL}login` &&
        originalRequest.url !== `${process.env.REACT_APP_SSO_API_URL}refresh-token`
      ) {
        if (!isRefreshing) {
          isRefreshing = true;

          httpClient
            .post(`${process.env.REACT_APP_SSO_API_URL}refresh-token`, {
              refresh: refreshToken,
            })
            .then((response: AxiosResponse) => {
              localStorage.setItem('authToken', JSON.stringify(response.data));

              isRefreshing = false;
              onRefreshed(response.data.accessToken);
            })
            .catch((error: AxiosError) => {
              localStorage.removeItem('authToken');
            });

          const retryOriginalRequest = new Promise<AxiosResponse>((resolve) => {
            subscribeTokenRefresh((token) => {
              // replace the expired token and retry
              if (originalRequest && originalRequest.headers) {
                // replace the expired token and retry
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
              }
              resolve(httpClient(originalRequest));
            });
          });

          return retryOriginalRequest;
        }
      } else {
        return Promise.reject(error);
      }
    }
  );

  const subscribeTokenRefresh = (cb: (token: string) => void): void => {
    refreshSubscribers.push(cb);
  };

  const onRefreshed = (token: string): void => {
    refreshSubscribers.map((cb) => cb(token));
  };
}
