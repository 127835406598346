/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {HiOutlineEye} from 'react-icons/hi'
import {useIntl} from 'react-intl'
import {HiOutlineEyeOff} from 'react-icons/hi'

const initialValues = {
  // email: 'admin@demo.com',
  // password: 'demo',
  email: 'info+1@edprime.co',
  password: 'Salesinfo@2023',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const {saveAuth, requestUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),

    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      login(values.email, values.password)
        .then(({data}) => {
          saveAuth(data)
          requestUser()
        })
        .catch((err) => {
          setStatus(
            intl.formatMessage({
              id: 'AUTH.VALIDATION.INVALID_LOGIN',
            })
          )
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
        })
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}

      <div className='text-center mb-2' style={{textAlign: 'center', padding: '5dp'}}>
        <h1 className='msg-login'>Hi there!</h1>
      </div>

      <div className='text-font'></div>

      <div>
        <div className='login-screem'>
          <b>SIGN IN TO YOUR ACCOUNT</b>
        </div>
      </div>

      {/* begin::Heading */}

      {/* begin::Login options */}
      <div className='row g-3 mb-9'>
        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}

          {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a> */}

          {/* end::Google link */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}

          {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a> */}

          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}

      {/* begin::Separator */}
      {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}

      {/* end::Separator */}
      {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8 d-flex'>
        {/* <label className='form-label fs-6 fw-bolder text-dark'>Email</label> */}
        {/* <span className='input-icon'><HiOutlineMail/></span> */}
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent input-box',
            {'is-invalid': formik.errors.email},
            {
              'is-valid': formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />

        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container '>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      {/* end::Form group */}
      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        {/* <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label> */}
        <div className='input-group'>
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent input-box border-l-non',
              {
                'is-invalid': formik.errors.password,
              },
              {
                'is-valid': formik.errors.password,
              }
            )}
          />

          <button
            type='button'
            className=' border-color-lock input-box'
            onClick={handleTogglePassword}
          >
            {/* <FontAwesomeIcon icon={showPassword ? 'lock-open' : 'lock'} />  */}

            {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
          </button>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='remember-container'>
        <label></label>
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
            <input
              type='checkbox'
              ng-model='singledata.remember'
              ng-value='1'
              name='remember'
            ></input>
            <div style={{marginLeft: '5px'}}>Remember Me</div>
          </div>

          <Link to='/auth/forgot-password' className='forgot-btn'>
            Forgot Password ?
          </Link>
        </div>
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='forgot-btn'>
          Forgot Password ?
        </Link> */}

        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}

      <div className='d-grid mb-2'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn login-btn'
          // style={{backgroundColor:"#08CCFE", borderRadius:"2rem",color:"#fff"}}

          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>SIGN IN</span>}

          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <button
          style={{marginTop: '18px'}}
          className='sign-otp'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>SIGN IN WITH OTP</span>}

          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>

        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}

      <div style={{textAlign: 'center', padding: '2px', marginTop: '5px'}}>
        <h3 className='login-hTag'>or connect through social</h3>
        <div>
          <a href='https://web.edprime.co/google_auth'>
            <img style={{width: '8%'}} src='https://web.edprime.co/assets/icon/google.png' />
          </a>

          <a href='https://web.edprime.co/facebook_auth'>
            <img
              style={{width: '8%', marginLeft: '3%'}}
              src='https://web.edprime.co/assets/icon/facebook.png'
            />
          </a>
        </div>
      </div>
    </form>
  )
}
