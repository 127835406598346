export const Loader = () => {
    return <>
       <img
          src='media/loading/loading.gif'
          className='light-logo'
          // alt="edprime-logo"
          alt="Loading..."
          style={{
            // height:"5% !important", 
            width:"12%",
            margin:"auto"
          }}
        />
    </>
}