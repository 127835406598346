import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import '../styles/custom.css';
import { Loader } from '../_utility/Loader'
// import { NotificationProvider } from '../_components/alertNotification/AlertNotification'


const App = () => {
  const {Loading} = useAuth()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {/* <NotificationProvider> */}
        <I18nProvider>
          {!Loading ? (
            <LayoutProvider>
              <AuthInit> 
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          ) : (
            <Loader />
          )}
        </I18nProvider>
      {/* </NotificationProvider> */}
    </Suspense>
  )
}

export {App}
