import axios from 'axios'

export const resource = 'users-data'
export const resource1 = 'dashboard'
// CREATE =>  POST: add a new item to the server

export function dashboardcreateItem(data: any) {
  // console.log('crud data', data)

  return axios.post(resource1, data)
}
