import React, {useState, useEffect} from 'react'
import { useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {connect} from 'react-redux'
import {FormattedMessage} from 'react-intl'
import {validateReferralToken} from '../core/_requests'
import {useAuth} from '../core/Auth'
import { redirectUrl } from '../../../../_utility/constant'
// import {validateReferralToken} from '../_redux/authCrud';
// import {NotificationManager} from '../../../../_component/react-notifications';

function ValidateToken() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {saveAuth, requestUser} = useAuth()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  // console.log('token: ', token);

  useEffect(() => {
    enableLoading()
    let storedToken = localStorage.getItem('authToken') as string | null
    let storedRefferalToken = storedToken ? JSON.parse(storedToken)?.referralToken : undefined
    // console.log('storedRefferalToken: ', storedRefferalToken);
    if (storedRefferalToken === token) {
      navigate('/dashboard')
      return
    }

    localStorage.removeItem('authToken')
    if (token) {
      // console.log('log 1')

      validateReferralToken(token)
        .then(({data}) => {
          // console.log('data: ', data);
          disableLoading()
          //   props.login(accessToken, refreshToken, referralToken);
          saveAuth(data)
          // requestUser()
          window.location.reload()
        })
        .catch((error) => {
          // NotificationManager.error(error, '', 3000, null, null, '');
          if (redirectUrl) {
            window.location.href = redirectUrl
          }
        })
        .finally(() => {
          navigate('/dashboard')
          disableLoading()
        })
    } else {
      if (redirectUrl) {
        window.location.href = redirectUrl
      }
    }
  }, [])

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  let loaderGifPath ="/media/loader/loading.gif"
  return (
    <div className='text-center mb-10 mb-lg-20'>
        <img style={{width:"50%"}}src={loaderGifPath} alt="loading" />
        {loading && <span className='ml-3 spinner spinner-white'></span>}
    </div>
    // <div className='login-form login-signin' id='kt_login_signin_form'>
    // </div>
  )
}

export default ValidateToken
