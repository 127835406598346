import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, SwitchBranchModel, TokenData, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, setInstituteBranch} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

import {redirectUrl, userRoles} from '../../../../_utility/constant'
// import jwt from 'jwt-decode';
// import { Rtl } from '../../../../_metronic/rtl/Rtl'
import {useLocation} from 'react-router-dom'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  requestUser: () => void
  currentUser: UserModel | undefined
  displayName: string | undefined
  Loading: boolean
  fulfillInstitutes: any[] | null
  requestBranches: any | null
  fulfillBranches: any[] | null // Add the branches property here
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  setDisplayName: Dispatch<SetStateAction<string | undefined>>
  setLoading: Dispatch<SetStateAction<boolean>>
  setFulfillInstitutes: Dispatch<SetStateAction<any[] | null>>
  setRequestBranches: Dispatch<SetStateAction<any | null>>
  setFulfillBranches: Dispatch<SetStateAction<any[] | null>> // Add the setBranches property here
  logout: () => void
  requestSetInstituteBranch: (requestData: SwitchBranchModel) => void
  queryAndbodyTableData: any
  setQueryAndbodyTableData: any
  handleInputChange: any
}

const initAuthContextPropsState: AuthContextProps = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  requestUser: () => {},
  currentUser: undefined,
  displayName: undefined,
  Loading: false,
  fulfillInstitutes: null,
  requestBranches: null,
  fulfillBranches: null, // Set branches to null in the initial state
  setCurrentUser: () => {},
  setDisplayName: () => {},
  setLoading: () => {},
  setFulfillInstitutes: () => {},
  setRequestBranches: () => {},
  setFulfillBranches: () => {}, // Set branches to an empty array in the initial state
  logout: () => {},
  requestSetInstituteBranch: () => {},
  queryAndbodyTableData: {},
  setQueryAndbodyTableData: () => {},
  handleInputChange: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const location = useLocation()

  const currentDate = new Date()

  // enDate is the current date
  const initialEndDate = currentDate.toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  // startDate is 28 days before the current date
  const startDate = new Date(currentDate)
  startDate.setDate(currentDate.getDate() - 28)
  const initialStartDate = startDate.toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [displayName, setDisplayName] = useState<string | undefined>()
  const [fulfillInstitutes, setFulfillInstitutes] = useState<any[] | null>([])
  const [fulfillBranches, setFulfillBranches] = useState<any[] | null>([])
  const [requestBranches, setRequestBranches] = useState<any | null>()
  const [Loading, setLoading] = useState<boolean>(false)
  const [queryAndbodyTableData, setQueryAndbodyTableData] = useState({
    // ... existing state properties
    startDate: initialStartDate,
    endDate: initialEndDate,
    pageNumber: 1,
    pageSize: 10,
    student: '',
    platform: '',
    institute: '',
    branch: '',
    class: '',
  })
  // console.log('location.pathname', location.pathname)

  const handleInputChange = (props: any) => {
    console.log('props: ', props)
    // console.log('pageSize', pageSize)
    const {startDate, endDate, newSize, newPage} = props
    console.log(' auth contex newPage', newPage)

    setQueryAndbodyTableData((prevData) => ({
      ...prevData,
      startDate: startDate ?? queryAndbodyTableData.startDate,
      endDate: endDate ?? queryAndbodyTableData.endDate,
      pageSize: newSize ?? queryAndbodyTableData.pageSize,
      pageNumber: newPage ?? queryAndbodyTableData.pageNumber,
    }))
  }

  useEffect(() => {}, [location.pathname])

  const saveAuth = async (auth: AuthModel | undefined) => {
    // console.log('auth data', auth)
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const requestUser = async () => {
    // console.log("reques 1");
    const {data: user} = await getUserByToken()
    if (
      (user && user.rouvrDefaultRole === userRoles.BranchAdmin) ||
      user.rouvrDefaultRole === userRoles.BranchAdmins ||
      user.rouvrDefaultRole === userRoles.superAdmin ||
      user.rouvrDefaultRole === userRoles.InstituteAdmin ||
      user.rouvrDefaultRole === 'Principal'
    ) {
      // const { data: institutes } = await getInstitutes();
      // const { data: branches } = await getBranches();
      // setFulfillInstitutes(institutes)
      // setFulfillBranches(branches)

      // if (branches && branches.length > 0) {
      //   const {data}  = await setInstituteBranch({
      //     moduleName: process.env.REACT_APP_MODULE_NAME,
      //     instituteId: branches[0].instituteId,
      //     branchId: branches[0].id,
      //   });

      //   setDisplayName(branches[0].name)
      //   // for set module like as library
      //   if(data){
      //     authHelper.setAuth(data)
      //     // const {
      //     //   data: {data: library},
      //     // } = await getLibrary(branches[0].name, branches[0].id)
      //     // const {data: authTokenData} = await setLibrary(library.id)

      //     // authHelper.setAuth(authTokenData)
      //   }
      // }
      setCurrentUser(user)
      authHelper.setAuth(user)
    }
    // else if (user && user.rouvrDefaultRole === userRoles.parents ||
    //             user.rouvrDefaultRole === userRoles.parent) {
    //   const { data: studentlist } = await getStudentProfiles();
    //   setCurrentUser(user)
    // }
    else {
      // navigate("unauthorize")
      logout()
    }
  }

  const requestSetInstituteBranch = async (requestData: SwitchBranchModel) => {
    setLoading(true)
    const {data} = await setInstituteBranch({
      moduleName: process.env.REACT_APP_MODULE_NAME,
      instituteId: requestData.instituteId,
      branchId: requestData.branchId,
    })

    setDisplayName(requestData.displayName)
    // for set module like as library
  }

  const logout = () => {
    authHelper.removeAuth()
    saveAuth(undefined)
    setCurrentUser(undefined)
    setDisplayName(undefined)
    setFulfillInstitutes(null)
    setFulfillBranches(null)
    setRequestBranches(null)
  }

  return (
    <AuthContext.Provider
      value={{
        queryAndbodyTableData,
        setQueryAndbodyTableData,
        handleInputChange,
        auth,
        saveAuth,
        requestUser,
        currentUser,
        displayName,
        setDisplayName,
        setCurrentUser,
        logout,
        fulfillInstitutes,
        setFulfillInstitutes,
        fulfillBranches,
        requestBranches,
        setFulfillBranches,
        setRequestBranches,
        requestSetInstituteBranch,
        Loading,
        setLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {
    auth,
    logout,
    setCurrentUser,
    setFulfillInstitutes,
    setFulfillBranches,
    setDisplayName,
    setLoading,
  } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      // console.log('reques 2');
      let token = JSON.parse(localStorage.getItem('authToken')!)
      // console.log('token: 12=>', token)
      if (!token) {
        if (redirectUrl) {
          window.location.href = redirectUrl
        }
      }

      try {
        if (!didRequest.current) {
          const {data: user} = await getUserByToken()
          // console.log('user:1234 ', user)
          if (
            (user &&
              (user.rouvrDefaultRole === userRoles.BranchAdmin ||
                user.rouvrDefaultRole === userRoles.InstituteAdmin ||
                user.rouvrDefaultRole === userRoles.BranchAdmins ||
                user.rouvrDefaultRole === userRoles.superAdmin)) ||
            user.rouvrDefaultRole === 'Principal'
          ) {
            // const { data: institutes } = await getInstitutes();
            // const { data: branches } = await getBranches();
            // if (!institutes.length || !branches.length) {
            //   //  navigate("unauthorize")
            // }
            // setFulfillInstitutes(institutes)
            // setFulfillBranches(branches)

            // if (branches && branches.length > 0) {
            //   let token = JSON.parse(localStorage.getItem('authToken')!)
            //   const tokenData:TokenData = jwtDecode(token.accessToken);

            //   // below shall be added into sso jwt
            //   const foundObject = branches.find((obj:any) =>
            //   obj.id === tokenData.branchId && obj.instituteId === tokenData.instituteId
            //   );
            //   setDisplayName(foundObject?.name)
            //   // for set module like as library
            //   if(foundObject){
            //     const {
            //       data: {data: library},
            //     } = await getLibrary(foundObject.name, foundObject.id)
            //     const {data: authTokenData} = await setLibrary(library.id)

            //     authHelper.setAuth(authTokenData)
            //     setLoading(false)
            //   }
            // }
            let token = JSON.parse(localStorage.getItem('authToken')!)
            // console.log('token: in auth', token);
            if (token) {
              authHelper.setAuth(token)
              setLoading(false)
            }

            setCurrentUser(user)
          } else {
            // navigate("unauthorize")
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
        setLoading(false)
      }

      return () => (didRequest.current = true)
    }

    const authToken = JSON.parse(localStorage.getItem('authToken')!)
    // auth && auth.referralToken
    if (authToken) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
      setLoading(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
