import React, { useEffect } from 'react'
import { redirectUrl } from '../../../../_utility/constant';

const Redirect = () => {
    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    }, [redirectUrl])
  return (
    <>
    </>
  )
}

export default Redirect